import { all, takeLatest, put, call } from 'redux-saga/effects'
import { APPS_LOAD_REQUEST, appsLoadSuccess } from '../actions/appStore'
import { appStoreResource } from '../../api'

const loadApps = function * (action) {
  try {
    const response = yield call(appStoreResource.all)
    if (!response.hasExceptions && !response.hasValidationErrors) {
      const { returnValue: apps } = response
      yield put(appsLoadSuccess(apps))
    }
  } catch (e) {
    console.log(e)
  }
}

export default function * appStoreSagas () {
  yield all([
    takeLatest(APPS_LOAD_REQUEST, loadApps)
  ])
}
