import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toggleCollapse } from '../../store/actions/navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NavigationItem from './NavigationItem'

import './Navigation.scss'

class Navigation extends Component {
  static displayName = 'Navigation'
  static propTypes = {
    items: PropTypes.array.isRequired,
    showChildren: PropTypes.bool
  }
  static defaultProps = {
    items: [],
    showChildren: false
  }

  constructor (props) {
    super(props)

    this.state = {
      collapsedItem: null,
      collapsedItemPos: 0,
      collapsedItemHeight: 0,
      closeTimeout: null
    }
  }

  mouseLeave = () => {
    this.setState({ collapsedItem: null })
  }

  onItemClick = (e, item) => {

  }

  componentDidMount () {

  }

  render () {
    const { children, items, isCollapsed, toggleCollapse } = this.props

    return (
      <div className={'navigation' + (!isCollapsed ? ' opened' : ' collapsed')}>
        <div className="logo">
          {children}
        </div>
        <div className="menu">
          {items.map((item, index) => {
            return item.header
              ? <div>
                (item.visibleOnCollapse || !isCollapsed) &&
                <div key={index} className="menu-header">
                  {item.title}
                </div>
              </div>
              : <NavigationItem key={index} item={item}/>
          })}
        </div>
        <button className="collapse-btn" onClick={toggleCollapse}>
          {!isCollapsed && <FontAwesomeIcon icon={['far', 'arrow-left']}/>}
          {isCollapsed && <FontAwesomeIcon icon={['far', 'arrow-right']}/>}
        </button>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isCollapsed: state.navigation.isCollapsed
})

const mapDispatchToProps = dispatch => ({
  toggleCollapse: () => dispatch(toggleCollapse())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
