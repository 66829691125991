export const navigation = [
  {
    href: '/',
    title: 'Hive',
    icon: 'dice-d20'
  },
  {
    title: 'Screens',
    icon: 'desktop',
    child: [
      {
        href: '/screens/groups',
        title: 'Screen Groups',
        icon: 'desktop'
      },
      {
        href: '/screens',
        title: 'Screen Manager',
        icon: 'bolt'
      }
    ]
  },
  {
    href: '/store',
    title: 'App Store',
    icon: 'store'
  },
  {
    href: '/apps',
    title: 'My Apps',
    icon: 'layer-group'
  },
  {
    href: '/channels',
    title: 'Channels',
    icon: 'play'
  },
  {
    href: '/schedules',
    title: 'Schedules',
    icon: 'clock'
  }
]
