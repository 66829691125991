import api from '../api'

const apps = [
  {
    id: 1,
    name: 'Photos',
    category: 'Social',
    description: 'Upload your own photos, add captions, edit photos with filters, ' +
      'and then show them off for your viewers!',
    icon: '/img/apps/photos_icon.png',
    images: [
      '/img/apps/photos1.jpg',
      '/img/apps/photos2.jpg',
      '/img/apps/photos1.jpg'
    ]
  },
  {
    id: 2,
    name: 'YouTube',
    category: 'Social',
    description: 'Over 1.9 Billion logged-in users visit YouTube each month and ' +
      'every day people watch over a billion hours of video and generate billions of views. ' +
      'Add YouTube to your hiveTV account to bring all of this content to your viewers!',
    icon: '/img/apps/youtube_icon.png',
    images: [
      '/img/apps/youtube1.jpg',
      '/img/apps/youtube2.jpg',
      '/img/apps/youtube3.jpg'
    ]
  },
  {
    id: 3,
    name: 'RSS',
    category: 'Feeds',
    description: 'Add any publicly accessible RSS feed to show your users relevant content as a ticker or as main content for ' +
      'your displays!',
    icon: '/img/apps/rss_icon.png',
    images: []
  },
  {
    id: 4,
    name: 'Meal Viewer',
    category: 'Hive Apps',
    description: 'Products are great, but they are static and not equipped to accommodate our ' +
      'ever-changing world. MealViewer is the world’s first custom-created platform for' +
      'school nutrition that is designed to adapt to the world as it changes.' +
      'MealViewer is built for food service professionals by food service professionals. ' +
      'That one fact sets us apart. We understand the challenge of managing and growing your program.',
    icon: '/img/apps/mv_icon.png',
    images: [
      '/img/apps/mealviewer1.jpg',
    ]
  },
  {
    id: 5,
    name: 'Weather Channel',
    category: 'Weather',
    description: 'Through The Weather Channel, weather.com, Weather Underground, ' +
      'Intellicast.com and third-party publishing partners, the company provides millions ' +
      'of people every day with the world\'s best weather forecasts, content and data, ' +
      'connecting with them through television, online, mobile and tablet screens. ' +
      'Add the Weather Channel now to get the day\'s top weather stories and expert insights from the most trusted source in weather.',
    icon: '/img/apps/weather_icon.png',
    images: [
      '/img/apps/weather1.jpg'
    ]
  },
  {
    id: 6,
    name: 'Instagram',
    category: 'Social',
    description: 'Instagram is one of the largest social media networks in the world, and now you can bring all of it to your hiveTV! ' +
      'Curate a feed specifically for your viewers to show them what\'s hot, what\'s trending, or show them items directly from your feed. ' +
      'Link your account today to enable your viewers to see their photos and more!',
    icon: '/img/apps/instagram_icon.png',
    images: [
      '/img/apps/instagram1.jpg',
      '/img/apps/instagram2.jpg'
    ]
  },
  {
    id: 7,
    name: 'ESPN',
    category: 'Sports',
    description: 'ESPN INTERNATIONAL IS THE WORLDWIDE LEADER IN SPORTS. The leading multinational, multimedia sports entertainment company ' +
      'features the broadest portfolio of sports assets with over 50 business entities. ' +
      'Add ESPN to get up-to-the-minute sports news coverage, scores, highlights and commentary for NFL, MLB, NBA, College Football, NCAA Basketball and more.',
    icon: '/img/apps/espn_icon.png',
    images: [
      '/img/apps/espn1.jpg',
      '/img/apps/espn2.jpg',
      '/img/apps/espn3.jpg'
    ]
  },
  {
    id: 8,
    name: 'CNN',
    category: 'News',
    description: 'CNN is among the world\'s leaders in online news and information delivery. Staffed 24 hours, ' +
      'seven days a week by a dedicated staff in CNN\'s world headquarters in Atlanta, Georgia, and in bureaus worldwide, CNN ' +
      'relies heavily on CNN\'s global team of almost 4,000 news professionals. CNN.com features the latest multimedia technologies, ' +
      'from live video streaming to audio packages to searchable archives of news features and background information updated continuously throughout the day. '
    ,
    icon: '/img/apps/cnn_icon.png',
    images: [
      '/img/apps/cnn1.jpg',
      '/img/apps/cnn2.jpg',
      '/img/apps/cnn3.jpg'
    ]
  },
  {
    id: 9,
    name: 'Sports Central',
    category: 'powered by Screenfeed®',
    description: 'Score the attention of your viewers with our original, eye-catching sports infographics.' +
      'We highlight the players, teams, divisions, conferences, and leagues that they care about most,' +
      'in near-real-time, to keep them in the game while they\'re out on the move.',
    icon: '/img/apps/sportscentral_icon.png',
    images: [
      '/img/apps/scentral1.jpg',
      '/img/apps/scentral2.jpg',
      '/img/apps/scentral3.jpg'
    ]
  },
  {
    id: 10,
    name: 'The List',
    category: 'powered by Screenfeed®',
    description: 'Feed viewers\' appetite for the latest, and greatest as they strive to stay current in today\'s ' +
      'fast-paced, and ever-changing world. This curated entertainment feed tracks the week\'s top performers at the box office,' +
      ' and on the charts, and includes an additional top-5 ranking that may cover anything from popular ‘90s comedies, to white-sand beaches around the world.'
    ,
    icon: '/img/apps/thelist_icon.png',
    images: [
      '/img/apps/list1.jpg',
      '/img/apps/list2.jpg',
      '/img/apps/list3.jpg'
    ]
  },
  {
    id: 11,
    name: 'Pop Quiz',
    category: 'powered by Screenfeed®',
    description: 'Entertain your audience with these broad-ranging challenges to their memories, and deductive dexterity.' +
      ' No need to keep official score; our design is all about collaborative, light-hearted fun for everyone. And the fun doesn\'t ' +
      'stop once viewers are out of sight of your screens. With any luck, they\'ll walk away with a nugget or two of new knowledge to ' +
      'share later with family, and friends.',
    icon: '/img/apps/popquiz_icon.png',
    images: [
      '/img/apps/quiz1.jpg',
      '/img/apps/quiz2.jpg',
      '/img/apps/quiz3.jpg'
    ]
  },
  {
    id: 12,
    name: 'Fox News',
    category: 'News',
    description: 'Fox News is a 24-hour all-encompassing news service dedicated to delivering breaking news as well as ' +
      'political and business news. A top five cable network, FNC has been the most-watched news channel in the country for ' +
      'more than 14 years and according to a Suffolk University/USA Today poll, is the most trusted television news source in the country.',
    icon: '/img/apps/fox_icon.png',
    images: [
      '/img/apps/fox1.jpg',
      '/img/apps/fox2.jpg',
      '/img/apps/fox3.jpg'
    ]
  },
  {
    id: 13,
    name: 'Yelp',
    category: 'Social',
    description: 'Yelp connects people with great local businesses. ' +
      'Yelp was founded in San Francisco in July 2004. Since then, Yelp communities have taken root ' +
      'in major metros across 32 countries. By the end of Q4 2018, Yelpers had written approximately 177 million rich, ' +
      'local reviews, making Yelp the leading local guide for real word-of-mouth on everything from ' +
      'boutiques and mechanics to restaurants and dentists. ',
    icon: '/img/apps/yelp_icon.png',
    images: [
      '/img/apps/yelp1.png',
    ]
  }
]

export const appStoreResource = {
  all () {
    api().get('/GetApplications')

    return new Promise((resolve) => {
      const res = apps.reduce((acc, curr) => {
        if (!acc[curr.category]) acc[curr.category] = []

        acc[curr.category].push(curr)
        return acc
      }, {})

      resolve(res)
    })
  },

  find (id) {
    return new Promise((resolve) => {
      resolve(apps.filter(app => app.id === parseInt(id))[0])
    })
  }
}
