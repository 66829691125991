import React, { Component } from 'react'
import './PowerButton.scss'

class PowerButton extends Component {
  static displayName = 'PowerButton'

  render () {
    const { isOn } = this.props

    return (
      <div className={'btn-power' + (isOn ? ' on' : '')}>
        <div className="icon"/>
      </div>
    )
  }
}

export default PowerButton
