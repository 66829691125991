import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { TopBar } from '../components/_shared'

class MyApps extends Component {
  static displayName = 'MyApps'

  render() {
    return (
      <div>
        <Helmet title="My Apps"/>
        <TopBar title="My Apps" icon="layer-group"/>
        <Container fluid>
          <h1>My Apps</h1>
        </Container>
      </div>
    )
  }
}

export default MyApps
