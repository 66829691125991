import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { TopBar, LoadingOverlay } from '../components/_shared'

class AppStore extends Component {
  static displayName = 'AppStore'

  render() {
    return (
      <div className="app-store">
        <Helmet title="App Store"/>
        <TopBar title="App Store" icon="store"/>
        <LoadingOverlay isLoading={true}>
          <h1>App Store</h1>
        </LoadingOverlay>
      </div>
    )
  }
}

export default AppStore
