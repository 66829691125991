import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { TopBar } from '../components/_shared'

class Schedules extends Component {
  static displayName = 'Schedules'

  render() {
    return (
      <div>
        <Helmet title="Schedules"/>
        <TopBar title="Schedules" icon="clock"/>
        <Container fluid>
          <h1>Schedules</h1>
        </Container>
      </div>
    )
  }
}

export default Schedules
