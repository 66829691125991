import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

import './DashboardMyApps.scss'

class DashboardMyApps extends Component {
  static displayName = 'DashboardMyApps'

  render () {
    return (
      <section className="mb-4">
        <Link to="/apps" className="text-muted small float-right mt-1">See all &gt;</Link>
        <h5>My Apps</h5>

        <div className="my-apps card">
          <ListGroup>
            <ListGroupItem>
              <img src="/img/apps/cnn_icon.png" alt="" className="app-icon"/> CNN
            </ListGroupItem>
            <ListGroupItem>
              <img src="/img/apps/instagram_icon.png" alt="" className="app-icon"/> Instagram
            </ListGroupItem>
            <ListGroupItem>
              <img src="/img/apps/espn_icon.png" alt="" className="app-icon"/> ESPN
            </ListGroupItem>
            <ListGroupItem>
              <img src="/img/apps/photos_icon.png" alt="" className="app-icon"/> Dynamic Photos
            </ListGroupItem>
          </ListGroup>
        </div>
      </section>
    )
  }
}

export default DashboardMyApps
