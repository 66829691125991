import React, { Component } from 'react'
import { NavDropdown } from 'react-bootstrap'
import UserAvatar from 'react-user-avatar'

class TopBarUser extends Component {
  static displayName = 'TopBarUser'

  render () {
    const userDropdownTitle = (<UserAvatar name="Eli Perez" size="26" color="#ffb300"/>)

    return (
      <NavDropdown title={userDropdownTitle} alignRight>
        <NavDropdown.Item href="#">Profile</NavDropdown.Item>
        <NavDropdown.Divider/>
        <NavDropdown.Item href="#">Sign Out</NavDropdown.Item>
      </NavDropdown>
    )
  }
}

export default TopBarUser
