import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { DefaultLayout } from './layouts'

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  if (Layout === undefined) {
    Layout = DefaultLayout
  }

  return (<Route {...rest} render={props => {
    const isAuthenticated = true

    return (rest.isProtected && !isAuthenticated
        ? <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
        : <Layout>
          <Component {...rest} {...props}/>
        </Layout>
    )
  }}/>)
}

export default AppRoute
