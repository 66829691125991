import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { PowerButton } from '../_shared'

import './DashboardNotification.scss'

class DashboardNotification extends Component {
  static displayName = 'DashboardNotification'

  render () {
    return (
      <section>
        <h5>Message from Hive</h5>

        <div className="notification card">
          <Container fluid>
            <Row>
              <Col sm={8}>
                <PowerButton isOn={true}/>
                <div className="content">
                  <h5 className="heading">You are currently on a free trial of Hive</h5>
                  <p>
                    Upgrade your account to get full access to all the Hive has to offer. Your free trial ends on <span
                    className="text-primary">October 31, 2019</span>.
                  </p>
                </div>
              </Col>
              <Col sm={4} className="actions">
                <Button variant="primary">Upgrade Now</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    )
  }
}

export default DashboardNotification
