import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { TopBar } from '../components/_shared'

class Screens extends Component {
  static displayName = 'Screens'

  render() {
    return (
      <div>
        <Helmet title="Screen Manager"/>
        <TopBar title="Screen Manager" icon="bolt"/>
        <Container fluid>
          <h1>Screen Manager</h1>
        </Container>
      </div>
    )
  }
}

export default Screens
