import { APPS_LOAD_SUCCESS } from '../actions/appStore'

const initialState = {
  list: {
    isLoading: true,
    apps: []
  }
}

const appsLoadSuccess = (prevState, { payload: { apps } }) => ({
  ...prevState,
  list: {
    isLoading: false,
    apps: apps
  }
})

export default (state = initialState, action) => {
  switch (action.type) {
    case APPS_LOAD_SUCCESS:
      return appsLoadSuccess(state, action)
    default:
      return state
  }
}
