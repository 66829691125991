import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Row } from 'react-bootstrap'

import './DashboardScreenManager.scss'

class DashboardScreenManager extends Component {
  static displayName = 'DashboardScreenManager'

  render () {
    return (
      <section>
        <Link to="/screens" className="text-muted small float-right mt-1">View offline devices &gt;</Link>
        <h5>Screen Manager</h5>

        <div className="screen-manager card">
          <Container fluid>
            <Row className="icons">
              <Col sm={12} md={6} className="on">
                <div className="icon">
                  <img src="/img/dashboard/screen_on.png" srcSet="/img/dashboard/screen_on@2x.png 2x" alt=""/>
                </div>

                <h1 className="total">37</h1>
                <p className="total-label text-primary">Online</p>
              </Col>
              <Col sm={12} md={6}>
                <div className="icon">
                  <img src="/img/dashboard/screen_off.png" srcSet="/img/dashboard/screen_off@2x.png 2x" alt=""/>
                </div>

                <h1 className="total">3</h1>
                <p className="total-label text-muted">Offline</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h2>Add More Licenses</h2>
                <p className="mb-4">
                  Ready to add your network of screens? <br/>
                  Purchase more screen licenses here.
                </p>

                <Button variant="primary mb-3">Add New Licenses</Button>

                <p className="text-muted small">Licenses in use 40 / 40</p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    )
  }
}

export default DashboardScreenManager
