import React, { Component } from 'react'
import { Container, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { TopBar } from '../components/_shared'

class Channels extends Component {
  static displayName = 'Channels'

  render() {
    return (
      <div>
        <Helmet title="Channels"/>
        <TopBar title="Channels" icon="play">
          <Button variant="primary">New Channel</Button>
        </TopBar>
        <Container fluid>
          <h1>Channels</h1>
        </Container>
      </div>
    )
  }
}

export default Channels
