import React from 'react'
import { connect } from 'react-redux'
import Navigation from './navigation/Navigation'
import { navigation } from '../navigation'

import './DefaultLayout.scss'

const DefaultLayout = props => (
  <div className={'layout' + (!props.isCollapsed ? ' menu-opened' : '')}>
    <Navigation items={navigation} showChildren={true}>
      <img src="/img/logo.png" alt="Hive"/>
    </Navigation>
    {props.children}
  </div>
)

const mapStateToProps = state => ({
  isCollapsed: state.navigation.isCollapsed
})

export default connect(mapStateToProps)(DefaultLayout)
