import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './Error.scss'

class Error extends Component {
  static displayName = 'Error'
  static propTypes = {
    code: PropTypes.number.isRequired
  }

  getError = () => {
    let error = {}

    switch (this.props.code) {
      case 401:
        error = {
          heading: '',
          message: ''
        }
        break
      case 403:
        error = {
          heading: '',
          message: ''
        }
        break
      case 404:
        error = {
          heading: 'Oops, page not found',
          message: 'The page you are looking for could not be found'
        }
        break
      case 500:
        error = {
          heading: '',
          message: ''
        }
        break
      default:
        error = {
          heading: 'Error',
          message: 'Something happened!'
        }
    }

    return error
  }

  randomBgImage = () => {
    const backgrounds = [
      '/img/error/error_bg1.jpg',
      '/img/error/error_bg2.jpg',
      '/img/error/error_bg3.jpg',
    ]

    return backgrounds[Math.floor(Math.random() * backgrounds.length)]
  }

  render () {
    const { code } = this.props
    const error = this.getError()
    const bgImage = this.randomBgImage()

    return (
      <div className="error-page">
        <Helmet title={`Error ${code}`}/>
        <div className="bg" style={{ backgroundImage: `url(${bgImage})` }}/>
        <div className="wrap">
          <div className="content">
            <h1>{code}</h1>
            <h4>{error.heading}</h4>
            <p>{error.message}</p>
            <p>
              Go back to <Link to="/">Hive</Link> or <Link to="#">contact us</Link> about the problem.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Error
