import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class NavigationItem extends Component {
  static displayName = 'NavigationItem'
  static propTypes = {
    item: PropTypes.object.isRequired
  }

  render () {
    const { item, isCollapsed } = this.props

    return (
      <div className="menu-item first-item">
        {item.href &&
        <NavLink to={item.href} disabled={item.disabled} className="item-link" activeClassName="active-item">
          <i className="item-icon">
            <FontAwesomeIcon icon={['far', item.icon]}/>
          </i>

          {!isCollapsed &&
          <span className="item-title">{item.title}</span>
          }
        </NavLink>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isCollapsed: state.navigation.isCollapsed
})

export default connect(mapStateToProps)(NavigationItem)
