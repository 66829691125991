import api from '../api'

export const sessionResource = {
  login,
  logout
}

function login (email, password) {
  const data = {
    email,
    password
  }

  return api().post('Viewertoken', data, { errorHandler: false }).then(handleResponse).then(user => {
    if (user.token) {
      localStorage.setItem('user', JSON.stringify(user))
    }

    return user
  })
}

function logout () {
  localStorage.removeItem('user')
}

function handleResponse (res) {
  if (res.status === 200) {
    return res.data
  } else {
    if (res.status === 401) {
      logout()
      window.location.reload(true)
    }

    const err = res.statusText
    return Promise.reject(err)
  }
}
