import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './DashboardFeatured.scss'

class DashboardFeatured extends Component {
  static displayName = 'DashboardFeatured'
  static propTypes = {
    backgroundImg: PropTypes.string,
    appName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }

  render () {
    const { backgroundImg, appName, description } = this.props

    return (
      <div>
        <h5>Featured for you</h5>

        <div className="featured card">
          {backgroundImg && <div className="bg" style={{ backgroundImage: `url('${backgroundImg}')` }}/>}
          <div className="content">
            <h5>Featured</h5>
            <h2>{appName}</h2>
            <p>{description}</p>

            <Button variant="primary">Add App</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardFeatured
