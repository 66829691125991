import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { TopBar } from '../components/_shared'
import {
  DashboardFeatured,
  DashboardMyApps,
  DashboardNotification,
  DashboardScreenManager,
  DashboardSupport
} from '../components/dashboard'

class Dashboard extends Component {
  static displayName = 'Dashboard'

  onHelp = () => {
    console.log('Handle page help')
  }

  render () {
    return (
      <div className="mb-5">
        <Helmet title="Dashboard"/>
        <TopBar help={this.onHelp}/>
        <Container fluid>
          <DashboardFeatured
            backgroundImg="/img/dashboard/featured.png"
            appName="ESPN"
            description="ESPN provides up-to-the-minute sports news coverage, scores, highlights, and commentary for NFL, MLB, NBA, College Football, NCAA Basketball and more."/>
          <DashboardNotification/>
          <Row>
            <Col sm={6}>
              <DashboardScreenManager/>
            </Col>
            <Col sm={6}>
              <DashboardMyApps className="mb-4"/>
              <DashboardSupport/>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Dashboard
