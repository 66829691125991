import { TOGGLE_COLLAPSE } from '../actions/navigation'

const navigation = JSON.parse(localStorage.getItem('navigation'))

const initialState = navigation
  ? { ...navigation }
  : { isCollapsed: false }

const toggleCollapse = (prevState) => {
  return {
    ...prevState,
    isCollapsed: !prevState.isCollapsed
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSE:
      return toggleCollapse(state)
    default:
      return state
  }
}
