import axios from 'axios'
import { sessionResource } from './'

export default () => {
  const baseDomain = 'https://hivetv-api-dev.azurewebsites.net/api/v4'
  const baseURL = `${baseDomain}/Viewer`

  const user = JSON.parse(localStorage.getItem('user'))
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if (user) {
    headers['Authorization'] = `Bearer ${user.token}`
  }

  const api = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })

  api.interceptors.request.use(config => {
    // Progress Start
    return config
  })

  api.interceptors.response.use(res => {
    // Progress Finish
    return res
  }, errorResponseHandler)

  return api
}

function errorResponseHandler (err) {
  // Passing the config property { errorHandler: false } in a request turns off error handling
  if (err.config.hasOwnProperty('errorHandler') && err.config.errorHandler === false) {
    return Promise.reject(err)
  }

  if (err.hasOwnProperty('response') && err.response.hasOwnProperty('status')) {
    const status = err.response.status

    if (status === 400) {
      console.log(err)
    } else if (status === 401) {
      sessionResource.logout()
      window.location.reload(true)
    } else if (status === 404) {

    } else if (status === 422) {

    } else if (status >= 500) {

    } else {

    }
  }
}
