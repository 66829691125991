import React from 'react'
import { Switch } from 'react-router-dom'
import { PublicLayout } from './layouts'

import AppRoute from './AppRoute'

import AppStore from './containers/AppStore'
import Channels from './containers/Channels'
import Dashboard from './containers/Dashboard'
import Error from './containers/Error'
import MyApps from './containers/MyApps'
import Schedules from './containers/Schedules'
import Screens from './containers/Screens'

const Routes = () => (
  <Switch>
    <AppRoute path="/apps" name="MyApps" component={MyApps} isProtected={true}/>
    <AppRoute path="/channels" name="Channels" component={Channels} isProtected={true}/>
    <AppRoute path="/schedules" name="Schedules" component={Schedules} isProtected={true}/>
    <AppRoute path="/screens" name="Screens" component={Screens} isProtected={true}/>
    <AppRoute path="/store" name="AppStore" component={AppStore} isProtected={true}/>
    <AppRoute path="/404" name="Error" component={Error} code={404} layout={PublicLayout} />
    <AppRoute path="/500" name="Error" component={Error} code={500} layout={PublicLayout} />
    <AppRoute path="/" name="Dashboard" component={Dashboard} isProtected={true}/>
  </Switch>
)

export default Routes
