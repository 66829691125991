import React, { Component } from 'react'
import './LoadingOverlay.scss'

class LoadingOverlay extends Component {
  static displayName = 'LoadingOverlay'

  render () {
    const { isLoading } = this.props

    return (
      <div>
        {isLoading && <div className="loading-overlay">
          <span>Loading</span>
        </div>}
        {!isLoading && this.props.children}
      </div>
    )
  }
}

export default LoadingOverlay
