import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navbar, Nav, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TopBarUser from './TopBarUser'

import './TopBar.scss'

class TopBar extends Component {
  static displayName = 'TopBar'
  static propTypes = {
    backTo: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    help: PropTypes.func
  }

  render () {
    const { backTo, icon, title, children, help } = this.props

    return (
      <Navbar variant="dark" sticky="top">
        {backTo && <Button to={backTo} className="btn-circle">
          <FontAwesomeIcon icon={['far', 'chevron-left']}/>
        </Button>}

        <Navbar.Brand>
          {icon && <FontAwesomeIcon icon={['far', icon]}/>}
          {title && <span>{title}</span>}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav"/>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Form inline className="mr-4">
              {children}
            </Form>

            {help && <Nav.Link href="#" onClick={help}>
              <FontAwesomeIcon icon={['far', 'question-circle']}/> Help
            </Nav.Link>}

            <TopBarUser/>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default TopBar
