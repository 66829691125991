import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { Helmet } from 'react-helmet'

class App extends Component {
  componentDidMount () {
    const splashScreen = document.getElementById('splash')

    if (splashScreen) {
      splashScreen.style.opacity = '0'
      setTimeout(() => {
        splashScreen && splashScreen.parentNode.removeChild(splashScreen)
      }, 600)
    }
  }

  render () {
    return (
      <BrowserRouter>
        <Routes/>
        <Helmet titleTemplate="%s - Hive" defaultTitle="Hive"/>
      </BrowserRouter>
    )
  }
}

export default App
