import React, { Component } from 'react'

import './DropShadow.scss'

class DropShadow extends Component {
  static displayName = 'DropShadow'

  render () {
    return (
      <svg height="0" xmlns="http://www.w3.org/2000/svg">
        <filter id="drop-shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="4"/>
          <feOffset dx="4" dy="4" result="offsetblur"/>
          <feFlood flood-color="rgba(0,0,0,0.5)"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </svg>
    )
  }
}

export default DropShadow
