import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import './DashboardSupport.scss'

class DashboardSupport extends Component {
  static displayName = 'DashboardSupport'

  render () {
    return (
      <section>
        <h5>Support</h5>

        <div className="support card">
          <Row>
            <Col sm="12" className="content">
              <h2>Need help?</h2>
              <p>Visit our knowledge center or get in touch with our support team.</p>

              <Button variant="primary" className="mb-3">Knowledge Center</Button>
              <br/>
              <Button variant="primary">Contact Us</Button>
            </Col>
          </Row>
          <img src="/img/dashboard/support_bg.png" srcSet="/img/dashboard/support_bg@2x.png 2x" alt=""/>
        </div>
      </section>
    )
  }
}

export default DashboardSupport
